<template>
  <div class="header">
    <div class="box searchInput">
      <el-autocomplete
        v-model="condition"
        v-no-emoji
        :fetch-suggestions="querySearch"
        :placeholder="$t('lang.newMyRecevierInputTitle')"
        @select="handleSelect"
        @keyup.enter.native="search"
        @input="removeSpecialStr"
        @focus="showSuggest"
        popper-class="receiverSearch myreceiverStyle"
        ref="receiverSearch"
        :hide-loading="true">
        <i
          class="el-icon-search el-input__icon"
          slot="suffix"
          @click="handleIconClick">
        </i>
        <template slot-scope="{ item }">
          <div class="name">{{ item }}</div>
        </template>
      </el-autocomplete>
    </div>
    <!-- 1280px以下屏幕显示 -->
    <div
      class="box f_left filters-toggle"
      @click='toggleFilterItem'>
      <i class="icon iconfont">&#xe69c;</i> {{$t('lang.filters')}}
    </div>

    <div
      class="statusBox"
      v-show='isShow.filterItem'>
      <span class="statusName">{{$t('lang.status')}}:</span>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        class="allStatus">
        {{$t('lang.all')}}
      </el-checkbox>
      <el-checkbox-group
        v-model="checkedCities"
        @change="handleCheckedCitiesChange"
        class="statusCheckBox">
        <el-checkbox
          v-for="filter in filterOptionObj"
          :label="filter"
          class="statusCheck"
          :class="{
            red:filter == 'live',
            green:filter == 'online',
            lightGrey:filter == 'offline',
          }"
          :key="filter">{{$t(`lang.${filter}`)}}
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div
      class="sortBox"
      v-show='isShow.filterItem'>
      <el-checkbox
        v-model="sort"
        @change="sortChange">{{$t('lang.newMyRecevierSort')}}
      </el-checkbox>
    </div>

    <div
      class="favoriteBox"
      v-show='isShow.filterItem'>
      <el-checkbox
        v-model="favorite"
        @change="favoriteChange">
        <span class="favoriteIconBox">
          <i class="iconfont android-favorite">&#xe6bb;</i>
        </span>
        <span>{{$t('lang.recevierMyFavorite')}}</span>
      </el-checkbox>
    </div>
    <div class="right filterRight">
      <div class="mutilControlBtn left">
        <a
          href="javascript:;"
          class="el-button el-button--success el-button--small green"
          @click.stop="goToPage({dataMark: 'tm'})">{{$t('lang.MultiControl')}}</a>
      </div>
      <Pagination
        style="float: left"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :pageSizes="[15]"
        :pageSize="15"
        :total="total"
        layout="prev, pageF, next, pageL, Size,nowSize, jumper" />
      <div class="changSize">
        <el-dropdown
          @command="changeSize"
          placement="bottom-end">
          <span class="el-dropdown-link">
            <i class="iconfont sourceListIcon"></i>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="selectSizeBox">
            <el-dropdown-item
              command="l"
              :class="[showSizeClass == 'l'?'greenColor':'']">
              <span
                class="iconfont icon-largesize"
                style="padding-right: 8px"></span>{{$t('lang.largeSize')}}</el-dropdown-item>
            <el-dropdown-item
              command="m"
              :class="[showSizeClass == 'm'?'greenColor':'']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe63d;</span>{{$t('lang.mediumSize')}}</el-dropdown-item>
            <el-dropdown-item
              command="s"
              :class="[showSizeClass == 's'?'greenColor':'']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe6ef;</span>{{$t('lang.smallSize')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <multi-Control
      :show="show"
      v-if="show.multiControl"></multi-Control>
    <!-- <div style="float: right">
      <Pagination
        style="float: left"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :pageSizes="[15]"
        :pageSize="15"
        :total="total"
        layout="prev, pageF, next, pageL, Size,nowSize, jumper" />
      <div class="changSize">
        <el-dropdown
          @command="changeSize"
          placement="bottom-end">
          <span class="el-dropdown-link">
            <i class="iconfont sourceListIcon"></i>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="selectSizeBox">
            <el-dropdown-item
              command="l"
              :class="[showSizeClass == 'l'?'greenColor':'']">
              <span
                class="iconfont icon-largesize"
                style="padding-right: 8px"></span>{{$t('lang.largeSize')}}</el-dropdown-item>
            <el-dropdown-item
              command="m"
              :class="[showSizeClass == 'm'?'greenColor':'']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe63d;</span>{{$t('lang.mediumSize')}}</el-dropdown-item>
            <el-dropdown-item
              command="s"
              :class="[showSizeClass == 's'?'greenColor':'']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe6ef;</span>{{$t('lang.smallSize')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div> -->
  </div>
</template>

<script>
import Bus from '@/assets/js/vueBus.js'
import Pagination from '@/components/Pagination'
import multiControl from '@/views/receivercontrol/multiControl'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'
import { mapMutations } from 'vuex'

const cityOptions = ['live', 'online', 'offline']
export default {
  components: {
    Pagination,
    multiControl
  },
  props: {
    resultLIst: Array
  },
  name: 'ReceiverSearch',
  data () {
    return {
      isShow: {
        filterItem: true
      },
      searchInfo: '', // 头部搜索框
      condition: '',
      filterOptionObj: ['live', 'online', 'offline'],
      params: {
        pageSize: 15, // 默认展示多少数据
        pageNum: 1, // 默认展示第一页
        scope: 1,
        condition: '', // 搜索框的参数
        favorite: '0', // 收藏的参数
        type: 'dn',
        pn: '',
        language: this.$i18n.locale,
        deviceSearchStatus: { // 状态的参数
          offline: 'true',
          online: 'true',
          live: 'true',
          sort: 'true'
        }
      },
      queryRlist: [],
      checkAll: true, //
      checkedCities: ['live', 'online', 'offline'],
      cities: cityOptions,
      isIndeterminate: false,
      sort: false, // sort参数默认为false
      favorite: false, // 收藏的参数
      total: 1, // 显示总数
      pageNum: 1, // 默认显示第一页
      pageSize: 15, // 每页显示多少条
      recommendSearch: [], // 推荐的搜索选项
      searchGuid: false, // 是否显示搜索指引,
      historyStatus: false,
      conditionSearch: [],
      conditionSearchSave: [],
      show: {
        multiControl: false
      }
    }
  },
  computed: {
    showSizeClass () {
      return this.$store.state.common.showRecevierSize
    }
  },
  mounted () {
    Bus.$on('updateMyReceiver', () => {
      this.userBehavior()
    })
  },
  methods: {
    // 搜索函数
    ...mapMutations('common', [
      'CHANGE_RecevierSize'
    ]),
    showMutilControl () {
      this.show.multiControl = !this.show.multiControl
    },
    goToPage (item) {
      localStorage.setItem('timeLockHisRouter', 'mr')
      this.$router.push({ path: `/${item.dataMark}` }).catch(() => {})
    },
    // 窗口大小调整
    handleResize () {
      if (document.body.clientWidth > 1023) {
        this.isShow.filterItem = true
      } else {
        this.isShow.filterItem = false
      }
    },
    // 切换显示filter item
    toggleFilterItem () {
      this.isShow.filterItem = !this.isShow.filterItem
    },
    search () { // 页面搜索框的回调方法
      this.params.pageNum = this.pageNum // 搜索到时候默认展示第一页
      this.params.condition = this.condition // 输入框的值赋给搜索接口作为参数
      myReceiversAnalyze('myReceiversSearchDestination', this.$route.path) // 谷歌统计 receiver是否搜索
      this.queryInfo() // 每次搜索都要调用queryRlist这个方法
      $('.receiverSearch').css('display', 'none')
      $('.box.searchInput input').blur() // NCC-1718
    },
    queryInfo () {
      Bus.$emit('isLoading', true)
      this.axios.post('/ccp/tvucc-device/userDevice/queryRlist', this.params, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8', timeout: 15000 },
        isCancelToken: true
      }).then(res => { // 所有R数据的接口
        if (!res.data.result) { // result为null的情况，添加默认值
          res.data.result = {
            bookmarkList: '',
            currentPageNum: this.params.pageNum,
            everyPageNum: this.params.pageSize,
            otherObject: null,
            paraMap: null,
            receiverList: '',
            resultList: [],
            sentNum: null,
            totalNum: 0,
            totalPageNum: 0,
            url: '',
            userflag: null
          }
        }
        this.queryRlist = res.data.result.resultList
        Bus.$emit('resultLIst', this.queryRlist) // 利用vueBus把搜索的R数据推送给content页面
        Bus.$emit('isLoading', false)
        this.total = res.data.result.totalNum || 0
        this.pageNum = res.data.result.currentPageNum
      }).catch(() => {
        Bus.$emit('isLoading', false)
      })
    },

    handleCheckAllChange (val) { // 全选框的回调事件
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
      if (this.checkAll) { // 判断这个全选的框是否被选中然后给接口传参
        this.params.deviceSearchStatus.offline = true // queryRlist的接口参数
        this.params.deviceSearchStatus.online = true // queryRlist的接口参数
        this.params.deviceSearchStatus.live = true // queryRlist的接口参数
        this.params.scope = '1'
        myReceiversAnalyze('myReceiversFilterAllSelected', this.$route.path) // 谷歌统计 receiver是否全选
      } else {
        this.params.deviceSearchStatus.offline = false
        this.params.deviceSearchStatus.online = false
        this.params.deviceSearchStatus.live = false
        this.params.scope = '0'
        myReceiversAnalyze('myReceiversFilterAllUnselect', this.$route.path) // 谷歌统计 receiver是否全选
      }
      this.queryInfo() // 全选时调用queryRlist方法
    },
    handleCheckedCitiesChange (value) { // 子组的筛选框
      const checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      if (this.checkAll) {
        this.params.scope = '1'
      } else {
        this.params.scope = '0'
      }
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      const statusArr = value // 通过索引去把每次筛选的条件传给queryRlist这个接口
      if (statusArr.indexOf('live') > -1) { // 控制live的参数
        this.params.deviceSearchStatus.live = true
        myReceiversAnalyze('myReceiversFilterLiveSelected', this.$route.path) // 谷歌统计 receiver是选中live
      } else {
        this.params.deviceSearchStatus.live = false
        myReceiversAnalyze('myReceiversFilterLiveUnselect', this.$route.path) // 谷歌统计 receiver是取消选中live
      }

      if (statusArr.indexOf('online') > -1) { // 控制online的参数
        this.params.deviceSearchStatus.online = true
        myReceiversAnalyze('myReceiversFilterOnlineSelected', this.$route.path) // 谷歌统计 receiver是选中online
      } else {
        this.params.deviceSearchStatus.online = false
        myReceiversAnalyze('myReceiversFilterOnlineUnselect', this.$route.path) // 谷歌统计 receiver是取消选中online
      }

      if (statusArr.indexOf('offline') > -1) { // 控制offline的参数
        this.params.deviceSearchStatus.offline = true
        myReceiversAnalyze('myReceiversFilterOfflineSelected', this.$route.path) // 谷歌统计 receiver是选中online
      } else {
        this.params.deviceSearchStatus.offline = false
        myReceiversAnalyze('myReceiversFilterOfflineUnselect', this.$route.path) // 谷歌统计 receiver是取消选中online
      }

      this.queryInfo() // 每次筛选时调用queryRlist方法
    },
    sortChange () { // sort筛选框的回调方法
      if (this.sort) {
        this.params.deviceSearchStatus.sort = true
        myReceiversAnalyze('myReceiversFilterSortSelected', this.$route.path) // 谷歌统计 receiver是选中short
      } else {
        this.params.deviceSearchStatus.sort = false
        myReceiversAnalyze('myReceiversFilterSortUnselect', this.$route.path) // 谷歌统计 receiver是取消选中sort
      }
      this.queryInfo() // 每次筛选时调用queryRlist方法
    },
    favoriteChange () { // 收藏的参数
      if (this.favorite) {
        this.params.favorite = '1'
        myReceiversAnalyze('myReceiversFilterFavoriteSelected', this.$route.path) // 谷歌统计 receiver是选中favouite
      } else {
        this.params.favorite = '0'
        myReceiversAnalyze('myReceiversFilterFavoriteUnselect', this.$route.path) // 谷歌统计 receiver是取消选中favorite
      }
      this.queryInfo() // 每次筛选时调用queryRlist方法
    },
    handleCurrentChange (val) {
      this.pageNum = val
      this.pageSize = 15
      this.params.pageSize = 15
      this.params.pageNum = val
      this.queryInfo()
      myReceiversAnalyze('myReceiversNavPagePrevious', this.$route.path) // 谷歌统计 receiver跳转页
    },
    querySearch (queryString, cb) {
      this.recommendSearch = []
      this.axios.get('/ccp/tvucc-device/userDevice/getCondition').then(v => {
        if (v.data.errorCode == '0x0') {
          this.conditionSearch = v.data.result
          if (this.condition) {
            let filterDate = []
            this.conditionSearch.map((item) => {
              if (item.toLowerCase().indexOf(this.condition.toLowerCase()) > -1) {
                filterDate.push(item)
              }
            })
            this.recommendSearch = filterDate
            cb(this.recommendSearch)
          } else {
            this.recommendSearch = this.conditionSearch
            cb(this.recommendSearch)
          }
        } else {
          this.recommendSearch = []
          cb(this.recommendSearch)
        }
      }).catch(() => {})
    },
    handleIconClick (ev) { // 点击搜索按钮
      this.search()
      $('.receiverSearch').css('display', 'none')
    },
    handleSelect (item) { // 建议搜索回调
      this.condition = item
      this.search()
    },
    userBehavior () { // 用户行为记录的接口
      Bus.$emit('isLoading', true)
      this.axios.post('/ccp/tvucc-user/userDevice/queryUserBehavior', { headers: { timeout: 15000 } }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.queryListR == null) {
            this.queryInfo() // 默认进页面调用用户行为记录如果没有用户行为记录就调用默认展示的接口queryRlist接口
          } else {
            const userBehaviorRlist = JSON.parse(res.data.result.queryListR) // 用户行为记录的R数据
            this.queryRlist = userBehaviorRlist.resultList
            Bus.$emit('resultLIst', this.queryRlist) // 利用vueBus把用户的的R数据推送给conent页面
            const userBehaviorStatus = JSON.parse(res.data.result.userBehavior.content) // 把用户行为的数据转json
            const tolpageObj = JSON.parse(res.data.result.queryListR) // 把分页数据转json
            if (userBehaviorStatus.condition) { // 输入框的赋值
              this.condition = userBehaviorStatus.condition
            }
            this.params.deviceSearchStatus.live = userBehaviorStatus.deviceSearchStatus.live
            this.params.deviceSearchStatus.offline = userBehaviorStatus.deviceSearchStatus.offline
            this.params.deviceSearchStatus.online = userBehaviorStatus.deviceSearchStatus.online
            this.params.deviceSearchStatus.sort = userBehaviorStatus.deviceSearchStatus.sort
            this.params.favorite = userBehaviorStatus.favorite
            this.params.condition = userBehaviorStatus.condition // 输入框的值赋给搜索接口作为参数
            this.params.scope = userBehaviorStatus.scope

            if (userBehaviorStatus.deviceSearchStatus.live) { // live
              if (this.checkedCities.indexOf('live') < 0) {
                this.checkedCities.push('live')
              }
            } else {
              if (this.checkedCities.indexOf('live') > -1) {
                this.checkedCities.splice(this.checkedCities.indexOf('live'), 1)
              }
            }

            if (userBehaviorStatus.deviceSearchStatus.online) { // online
              if (this.checkedCities.indexOf('online') < 0) {
                this.checkedCities.push('online')
              }
            } else {
              if (this.checkedCities.indexOf('online') > -1) {
                this.checkedCities.splice(this.checkedCities.indexOf('online'), 1)
              }
            }

            if (userBehaviorStatus.deviceSearchStatus.offline) { // offline
              if (this.checkedCities.indexOf('offline') < 0) {
                this.checkedCities.push('offline')
              }
            } else {
              if (this.checkedCities.indexOf('offline') > -1) {
                this.checkedCities.splice(this.checkedCities.indexOf('offline'), 1)
              }
            }

            if (userBehaviorStatus.deviceSearchStatus.sort) { // 是否按类型排序
              this.sort = true
            } else {
              this.sort = false
            }

            if (userBehaviorStatus.favorite == '0') { // 是否收藏
              this.favorite = false
            } else {
              this.favorite = true
            }

            if (userBehaviorStatus.scope == '1') { // 是否全选
              this.checkAll = true
            } else {
              this.checkAll = false
            }

            if (tolpageObj.totalPageNum) { // 显示的总页数
              this.total = tolpageObj.totalPageNum * tolpageObj.everyPageNum
            } else {
              this.total = 0
            }

            if (tolpageObj.currentPageNum) { // 显示的当前页
              this.pageNum = tolpageObj.currentPageNum
            } else {
              this.pageNum = 1
            }
          }
        }
        Bus.$emit('isLoading', false)
      }).catch(() => {
        this.queryInfo()
      })
    },
    removeSpecialStr () { // 出去指定的特殊字符
      if (this.condition) {
        this.condition = this.condition.replace(/["'</>%)(\s+\\]/g, '')
      }
      if (this.recommendSearch.length > 0) {
        $('.receiverSearch').css('display', 'block')
      }
    },
    showSuggest () {
      if (this.recommendSearch.length > 0) {
        $('.receiverSearch').css('display', 'block')
      }
    },
    changeSize (command) {
      switch (command) {
        case 's':
          myReceiversAnalyze('myRecevierChangeSmall', this.$route.path)
          break
        case 'm':
          myReceiversAnalyze('myRecevierChangeMedium', this.$route.path)
          break
        case 'l':
          myReceiversAnalyze('myRecevierChangeLarge', this.$route.path)
      }
      this.CHANGE_RecevierSize(command)
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.userBehavior()
    if (document.body.clientWidth > 1023) {
      this.isShow.filterItem = true
      return
    }
    this.isShow.filterItem = false
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang='less' scoped>
.greenColor {
  color: #33ab4f;
}
::v-deep .el-icon-search {
  cursor: pointer;
}

::v-deep .el-autocomplete {
  width: 100%;
  margin-left: 10px;
}

::v-deep .el-input__clear {
  float: left;
}

::v-deep .el-autocomplete .el-input__inner::placeholder {
  color: #666;
}

::v-deep .box.searchInput input.el-input__inner {
  height: 32px;
  line-height: 32px;
}

::v-deep .box.searchInput .el-input .el-input__icon {
  line-height: 32px;
}

.header {
  height: 50px;
  max-width: 1920px;
  margin-top: 27.5px;
  margin-left: 8px;
  margin-bottom: 27.5px;
  position: relative;
  .mutilControlBtn {
    margin: 0 5px;
    display: inline-block;
    height: 32px;
  }
}

.searchInput {
  width: 210px;
  float: left;
  border: none;
  position: relative;
}

::v-deep .el-input .el-input__inner {
  border: 1px solid rgba(102, 102, 102, 1);
}

.statusName {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 40px;
  margin-left: 60px;
  padding: 0 10px;
}

.statusBox {
  float: left;
}

.allStatus {
  float: left;
  line-height: 40px;
}

.statusCheckBox {
  float: left;
  line-height: 40px;
}

.statusCheck {
  padding: 0 8px;
}

.sortBox {
  float: left;
  padding-left: 45px;
  line-height: 40px;
}

.favoriteBox {
  float: left;
  padding-left: 55px;
  line-height: 40px;
}

::v-deep .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: none;
  border-color: rgba(102, 102, 102, 1);
}

.android-favorite {
  color: #ff4d4f;
  font-size: 22px;
}

::v-deep
  .el-checkbox
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner::before {
  display: none;
}

::v-deep .el-checkbox .el-checkbox__inner {
  border: 2px solid rgba(102, 102, 102, 1);
  background: none;
}

::v-deep .page_dropdown {
  display: none;
}

.favoriteIconBox {
  float: left;
  padding: 2px;
  margin-left: -5px;
}

.box.filters-toggle {
  border: 1px solid #666666 !important;
  padding: 0 17px;
  line-height: 32px;
  height: 32px;;
  border-radius: 5px;
  cursor: pointer;
  color: #999999;
  margin-left: 20px;
  display: none;
}

.statusBox,
.sortBox,
.favoriteBox {
  display: block;
}

.header .paginationBox {
  margin-top: 2px;
}

@media (max-width: 1345px) {
  .sortBox,
  .favoriteBox {
    padding-left: 15px;
  }

  .statusName {
    margin-left: 8px;
    padding-right: 5px;
  }

  .statusBox .el-checkbox {
    margin-right: 0;
  }

  ::v-deep .favoriteBox .el-checkbox__label {
    padding-left: 4px;
  }

  ::v-deep .sortBox .el-checkbox__label {
    padding-left: 4px;
  }

  ::v-deep .allStatus .el-checkbox__label {
    padding-left: 4px;
  }

  ::v-deep .statusCheckBox .el-checkbox__label {
    padding-left: 4px;
  }

  .statusCheckBox .statusCheck {
    padding-right: 0;
    margin-right: 0;
  }

  .header .searchInput {
    width: 200px;
  }
}

@media (max-width: 1023px) {
  .box.filters-toggle {
    display: block;
  }

  .statusBox {
    position: absolute;
    left: -10px;
    top: 40px;
  }

  .sortBox {
    position: absolute;
    left: 310px;
    top: 40px;
  }

  .favoriteBox {
    position: absolute;
    left: 450px;
    top: 40px;
  }
}

@media (max-width: 600px) {
  .sortBox {
    left: 265px;
  }

  .favoriteBox {
    left: 370px;
  }

  .header .searchInput {
    width: 150px;
  }

  ::v-deep .el-checkbox__label {
    font-size: 12px;
  }

  .box.filters-toggle {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 518px) {
  ::v-deep .el-checkbox .el-checkbox__inner {
    width: 15px;
    height: 15px;
  }

  ::v-deep .el-checkbox .el-checkbox__inner::after {
    left: 3px;
    top: 0;
  }

  .sortBox {
    left: 258px;
  }

  .favoriteBox {
    left: 362px;
  }
}
.changSize {
  float: right;
  position: relative;
  margin-right: 15px;
  .el-dropdown-link {
    cursor: pointer;
    color: #dbdbdb;
  }
  .el-icon-arrow-down {
    position: absolute;
    top: 11px;
  }

  .el-icon-arrow-down:before {
    font-size: 8px;
    font-weight: 900;
  }
  .sourceListIcon:before {
    content: '\E684';
    font-size: 24px;
    cursor: pointer;
  }
}
</style>
